import React, { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './Booking.css';

const Booking = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    date: new Date(),
    time: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');

  const times = [
    '09:00 AM EST',
    '09:30 AM EST',
    '10:00 AM EST',
    '10:30 AM EST',
    '11:00 AM EST',
    '11:30 AM EST',
    '12:00 PM EST',
    '12:30 PM EST',
    '01:00 PM EST',
    '01:30 PM EST',
    '02:00 PM EST',
    '02:30 PM EST',
    '03:00 PM EST',
    '03:30 PM EST',
    '04:00 PM EST',
    '04:30 PM EST',
    '05:00 PM EST',
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleDateChange = (date) => {
    setFormData((prevData) => ({ ...prevData, date }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setResponseMessage('');

    try {
      const response = await fetch('/api/book-consultation', { // Updated URL
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setResponseMessage('Your booking has been confirmed!');
        setFormData({ name: '', email: '', date: new Date(), time: '' });
      } else {
        setResponseMessage('Something went wrong. Please try again later.');
      }
    } catch (error) {
      setResponseMessage('An error occurred. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="booking-container">
      <header className="booking-header">
        <h1 className="booking-logo">Book Your Consultation</h1>
        <p>Book your complementary hour consultation and recieve a walk through Moccet</p>
      </header>
      <form className="booking-form" onSubmit={handleSubmit}>
        <div className="input-container123">
          <input
            className="input-box123"
            type="text"
            name="name"
            placeholder="Your Name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="input-container123">
          <input
            className="input-box123"
            type="email"
            name="email"
            placeholder="Your Email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="calendar-container">
          <Calendar
            onChange={handleDateChange}
            value={formData.date}
            minDate={new Date()} // Disable past dates
            tileClassName={({ date, view }) => {
              if (view === 'month') {
                return 'calendar-tile';
              }
              return null;
            }}
          />
        </div>
        <div className="input-container123">
          <select
            className="input-box1234"
            name="time"
            value={formData.time}
            onChange={handleChange}
            required
          >
            <option value="" disabled>
              Select a Time (EST)
            </option>
            {times.map((time, index) => (
              <option key={index} value={time}>
                {time}
              </option>
            ))}
          </select>
        </div>
        <button className="link-button123" type="submit" disabled={isSubmitting}>
          {isSubmitting ? 'Booking...' : 'Book Now'}
        </button>
      </form>
      {responseMessage && <p className="response-message">{responseMessage}</p>}
    </div>
  );
};

export default Booking;
