// src/components/ApplicationsList.js

import React, { useEffect, useState } from 'react';
import { auth, db } from '../../firebaseConfig';
import {
  collection,
  query,
  where,
  getDoc,
  getDocs,
  doc,
  addDoc,
  setDoc,
  updateDoc,
} from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import Footer from '../Footer';
import rightarrow from '../../images/right-arrow.png';
import leftarrow from '../../images/left-arrow.png';
import greentick from '../../images/green-tick.png'; // Import green tick
import './ApplicationsList.css';

function ApplicationsList() {
  const [applications, setApplications] = useState([]);
  const [currentApplicationIndex, setCurrentApplicationIndex] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const [studentDetails, setStudentDetails] = useState(null);

  useEffect(() => {
    const fetchApplications = async () => {
      try {
        const user = auth.currentUser;
        if (user) {
          const applicationsQuery = query(
            collection(db, 'applications'),
            where('businessId', '==', user.uid)
          );
          const applicationsSnapshot = await getDocs(applicationsQuery);
          const applicationsList = applicationsSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setApplications(applicationsList);
        }
      } catch (error) {
        console.error('Error fetching applications:', error);
      }
    };
    fetchApplications();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoaded(true);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  const handleNextApplication = () => {
    setCurrentApplicationIndex((prevIndex) => (prevIndex + 1) % applications.length);
  };

  const handlePreviousApplication = () => {
    setCurrentApplicationIndex((prevIndex) => (prevIndex - 1 + applications.length) % applications.length);
  };

  const handleConnect = async (application) => {
    try {
      const user = auth.currentUser;
      if (!user) {
        console.error('User not authenticated.');
        return;
      }
  
      // Fetch the business user's data to get the businessName
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      let businessName = 'Unnamed Business';
      if (userDoc.exists()) {
        businessName = userDoc.data().businessName || 'Unnamed Business';
      }
  
      // Fetch the project document using projectId
      const projectRef = doc(db, 'projects', application.projectId);
      const projectDoc = await getDoc(projectRef);
  
      if (projectDoc.exists()) {
        // Update the project with collaboration details
        await updateDoc(projectRef, {
          isCollaborating: true,
          studentId: application.studentId,
          collaboratingBusinessId: user.uid,
        });
  
        // Update the corresponding application document
        const applicationRef = doc(db, 'applications', application.id);
        await updateDoc(applicationRef, {
          isCollaborating: true,
        });
  
        // Create a chat room with a specific ID
        const chatRoomId = `${user.uid}_${application.studentId}_${application.projectId}`;
        const chatRoomRef = doc(db, 'chatRooms', chatRoomId);
  
        const chatRoomDoc = await getDoc(chatRoomRef);
  
        if (!chatRoomDoc.exists()) {
          await setDoc(chatRoomRef, {
            businessId: user.uid,
            studentId: application.studentId,
            projectId: application.projectId,
            businessName: businessName,
            studentName: application.studentName,
            projectName: application.projectName,
            createdAt: new Date(),
            messages: [],
            participants: [user.uid, application.studentId],
            unreadCounts: {
              [user.uid]: 0,
              [application.studentId]: 0,
            },
          });
        }
  
        // Update the state to reflect collaboration in the UI
        setApplications((prevApplications) =>
          prevApplications.map((app) =>
            app.id === application.id ? { ...app, isCollaborating: true } : app
          )
        );
      } else {
        console.error('Project not found!');
      }
    } catch (error) {
      console.error('Error creating or accessing chat room or updating project:', error);
    }
  };
  

  const openPopup = async () => {
    try {
      const currentApplication = applications[currentApplicationIndex];
      const studentId = currentApplication.studentId;

      // Fetch student details from Firestore
      const studentDocRef = doc(db, 'users', studentId);
      const studentDoc = await getDoc(studentDocRef);

      if (studentDoc.exists()) {
        const studentData = studentDoc.data();
        // Exclude email and password
        const { university, degree, year, skills, previousProjects } = studentData;
        setStudentDetails({ university, degree, year, skills, previousProjects });
        setShowPopup(true);
      } else {
        console.error('Student details not found!');
      }
    } catch (error) {
      console.error('Error fetching student details:', error);
    }
  };

  const closePopup = () => {
    setShowPopup(false);
    setStudentDetails(null);
  };

  if (applications.length === 0) {
    return <p>No applications yet.</p>;
  }

  const currentApplication = applications[currentApplicationIndex];

  return (
    <div className={`dashboard-container3 ${loaded ? 'loaded' : ''}`}>
      <div className="upper-section3">
        <div className="applications-content">
        <div className="applications-container1">
          <p>Assess Candidates</p>
        </div>
          <div className="application">
            <div className="arrow-left" onClick={handlePreviousApplication}>
              <img src={leftarrow} alt="Arrow" className="arrow" />
            </div>
            <div className="application-info">
              <h3 className="student-name">{currentApplication.studentName}</h3>
              <p className="university-name">{currentApplication.university}</p>
              <p className="degree-info">
                {currentApplication.studentName} studies {currentApplication.degree} and is a{' '}
                {currentApplication.year} year student.
              </p>
              <p className="project-name">Project: {currentApplication.projectName}</p>
              {/* View Full Profile Link */}
              <p className="view-profile" onClick={openPopup}>
                View Full Profile
              </p>
              {currentApplication.isCollaborating ? (
                <div className="collaboration-section">
                  <img src={greentick} alt="Green Tick" className="green-tick" />
                  <p className="collaborate-text" onClick={() => navigate(`/business-chats`)}>
                    Collaborate
                  </p>
                </div>
              ) : (
                <p className="offer" onClick={() => handleConnect(currentApplication)}>
                  Offer
                </p>
              )}
            </div>
            <div className="arrow-right" onClick={handleNextApplication}>
              <img src={rightarrow} alt="Right Arrow" className="arrow" />
            </div>
          </div>
          <div className="dots">
            {applications.map((_, index) => (
              <span key={index} className={`dot ${index === currentApplicationIndex ? 'active' : ''}`}></span>
            ))}
          </div>
        </div>
      </div>
      {/* Popup for Student Details */}
      {showPopup && studentDetails && (
        <div className="popup-overlay" onClick={closePopup}>
          <div className="popup-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-button" onClick={closePopup}>
              X
            </button>
            <h2>{currentApplication.studentName}'s Profile</h2>
            <p>
              <strong>University:</strong> {studentDetails.university}
            </p>
            <p>
              <strong>Degree:</strong> {studentDetails.degree}
            </p>
            <p>
              <strong>Graduation Year:</strong> {studentDetails.year}
            </p>
            <p>
              <strong>Skills:</strong> {studentDetails.skills}
            </p>
            <p>
              <strong>Previous Projects:</strong> {studentDetails.previousProjects}
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default ApplicationsList;
