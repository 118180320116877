import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
  const navigate = useNavigate();

  return (
    <div className="footer">
      <div className="footer-content">
        <p className="footer-description">
          Moccet’s AI-powered brief creation tool transforms your ideas into professional project briefs with market insights, strategic plans, and actionable deliverables. This feature is available to all, however, connecting to talent and delivering the brief is exclusively for registered users on the Moccet platform. Simply submit your project description, and our AI will provide tailored outputs to guide your project toward success. Please note that these insights are for informational purposes only and should not be considered professional financial or business advice. Moccet assumes no liability for decisions made based on AI-generated recommendations.
        </p>
        <p className="footer-description">
          Our platform is continuously evolving, with new features and updates being rolled out regularly. Availability of certain features or services may vary by region, and some functionalities may not be accessible in all locations. By using Moccet, you agree to our terms of service and privacy policy, available for review any time on our website. For more information or assistance, contact Moccet’s customer support team.
        </p>
        <p className="footer-description">
          More ways to connect: Post your first project or explore our features today at <a href="https://www.moccet.com" className="footer-link">www.moccet.com</a>. For inquiries, reach us at <a href="mailto:info@moccet.com" className="footer-link">info@moccet.com</a>.
        </p>
      </div>
      <div className="footer-bottom">
        <p>United States <br/> Copyright © 2024 Moccet Ltd. All rights reserved.</p>
        <div className="footer-links">
          <p onClick={() => navigate('/privacy-policy')}>Privacy Policy</p>
          <p onClick={() => navigate('/terms-of-use')}>Terms of Service</p>
          <p onClick={() => navigate('/site-map')}>Site Map</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
