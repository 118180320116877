// HomePage.js

import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './HomePage.css';
import Dashboard from '../components/dashboards/Dashboard';
import Footer from '../components/Footer';
import Create from '../components/home/Create';
import Work from '../components/home/Work';
import HowItWorks from '../components/home/HowItWorks';
import ValueProposition from '../components/home/ValueProposition';
import MarketingVid from '../components/home/MarketingVid';
import ProjectOptimization from '../components/projects/ProjectOptimization';

function HomePage() {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    AOS.init({ duration: 1000 });

    const handleScroll = () => {
      const position = window.pageYOffset;
      setScrollPosition(position);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="home-page-container">
      {/* Home Page Content */}
      <div className="home-page">
        <div className="dashboard-container">
          <ProjectOptimization />
        </div>
        <div className="about-us-container">
        <div data-aos="fade-up" id="how-it-works">
            <ValueProposition />
          </div>
          <div data-aos="fade-up" id="how-it-works">
            <HowItWorks />
          </div>
          <div data-aos="fade-up" id="work">
            <Work />
          </div>
          <div data-aos="fade-up" id="create">
            <Create />
          </div>
        </div>
        <div data-aos="fade-up">
            <MarketingVid />
          </div>
        <Footer />
      </div>
    </div>
  );
}

export default HomePage;
