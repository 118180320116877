import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Dashboard.css';

const Dashboard = () => {
  const navigate = useNavigate();
  const [animate, setAnimate] = useState(false);

  const placeholders = [
    'Describe your project - it all starts here.',
    'Whats your next big idea? It starts here.',
    'Need an MVP for your startup? Get started.',
    'New marketing campaign? It starts here.',
    'Looking for a web developer? Start now.',
    'Bold ideas to small details - it starts here.',
    'Type your vision. It starts here.',
    'What will you create? It starts here.'
  ];

  const [currentPlaceholderIndex, setCurrentPlaceholderIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  const handleInputClick = () => {
    setAnimate(true);
  };

  useEffect(() => {
    if (animate) {
      const timer = setTimeout(() => {
        navigate('/project-optimization');
      }, 300); // match CSS transition

      return () => clearTimeout(timer);
    }
  }, [animate, navigate]);

  // Cycle through placeholders
  useEffect(() => {
    const interval = setInterval(() => {
      setIsAnimating(true);

      setTimeout(() => {
        setCurrentPlaceholderIndex((prevIndex) => (prevIndex + 1) % placeholders.length);
      }, 400); // halfway point for animation

      setTimeout(() => {
        setIsAnimating(false);
      }, 800); // end of animation
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={`dashboard12 ${animate ? 'open' : ''}`}>
      <header className="hero-section">
        <h1 className="main-title">Complete any project with Moccet</h1>
        <p className="subtitle">Connect with Harvard and MIT talent to achieve the best results for your projects, powered by <span className="highlight">Moccet AI.</span></p>

        <div className="input-container12">
          <input
            className="input-box12"
            type="text"
            readOnly
            onFocus={(e) => e.target.blur()}
            onClick={handleInputClick}
          />
          <div className="placeholder-wrapper">
            <div className={`placeholder-text ${isAnimating ? 'animate' : ''}`}>
              {placeholders[currentPlaceholderIndex]}
            </div>
          </div>

          <button className="arrow-button" onClick={handleInputClick}>
            <span className="arrow-icon">↑</span>
          </button>
        </div>
      </header>
    </div>
  );
};

export default Dashboard;
