import React, { useEffect, useState } from 'react';
import { auth, db } from '../../firebaseConfig';
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
} from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import Footer from '../Footer';
import './BusinessChats.css';

function BusinessChats() {
  const [chats, setChats] = useState([]);
  const [businessName, setBusinessName] = useState('');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = auth.currentUser;
        if (user) {
          await fetchBusinessName(user);
          await fetchChats(user);
        } else {
          console.error('No user is signed in.');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const fetchBusinessName = async (user) => {
    try {
      const userDocRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        if (userData.role === 'business') {
          setBusinessName(userData.businessName || '');
        } else {
          console.error('User is not a business.');
        }
      } else {
        console.error('No user document found.');
      }
    } catch (error) {
      console.error('Error fetching business name:', error);
    }
  };

  const fetchChats = async (user) => {
    try {
      const chatsQuery = query(
        collection(db, 'chatRooms'),
        where('participants', 'array-contains', user.uid)
      );
      const chatSnapshot = await getDocs(chatsQuery);
      const chatsList = await Promise.all(
        chatSnapshot.docs.map(async (doc) => {
          const chatData = doc.data();
          const otherUserId = chatData.participants.find(
            (id) => id !== user.uid
          );

          // Fetch studentName and projectName if not already present
          if (!chatData.studentName || !chatData.projectName) {
            // Fetch from applications or other sources as needed
            // For this example, we'll assume they are present
          }

          return {
            id: doc.id,
            ...chatData,
          };
        })
      );

      setChats(chatsList);
    } catch (error) {
      console.error('Error fetching chats:', error);
    }
  };

  if (loading) return <p>Loading your chats...</p>;

  return (
    <div className="chats-container">
      <h1>{businessName}</h1>
      <h2>Collaborate</h2>
      <div className="chat-items-container">
        {chats.length > 0 ? (
          chats.map((chat) => (
            <div
              key={chat.id}
              className="chat-item"
              onClick={() => navigate(`/chat/${chat.id}`)}
            >
              <h3>
                {`${chat.studentName || 'Unnamed Student'} `}
                <span className="chat-item-x">X</span>
                {` ${chat.projectName || 'Unnamed Project'}`}
              </h3>
            </div>
          ))
        ) : (
          <p>No chats available.</p>
        )}
      </div>
    </div>
  );
}

export default BusinessChats;
