import React, { useState, useMemo } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { useNavigate } from 'react-router-dom';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './ValueProposition.css';
import image1 from '../../images/project1.png';
import image2 from '../../images/project2.png';
import image3 from '../../images/project3.png';

const SwipeableCards = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();

  const cards = useMemo(
    () => [
      {
        title: 'Step One: Refine your vision',
        heading: 'Your Ideas, Our AI',
        description:
          'Share your vision—no need for perfection. Moccet’s AI refines your idea into a professional brief, complete with market insights, a strategic plan, and clear deliverables to set your project up for success.',
        buttonText: 'Upload',
        image: image1,
      },
      {
        title: 'Step Two: AI matcher to find the best talent',
        heading: 'Make the Perfect Team',
        description:
          'Once your brief is ready, you set the project budget. Moccet instantly matches you with the best talent exclusively from Harvard and MIT. Through the platform, you’ll collaborate in real-time, ensuring smooth communication and progress at every stage.',
        buttonText: 'Upload',
        image: image2,
      },
      {
        title: 'Step Three: Watch your business grow',
        heading: 'Deliver. Add Value. Repeat.',
        description:
          'Receive a completed project that’s ready to add value to your business. Whether it’s solving problems, creating opportunities, or driving growth, Moccet makes it easy to come back for more. Your success is just the beginning.',
        buttonText: 'Upload',
        image: image3,
      },
    ],
    []
  );

  const handleChange = (index) => setCurrentIndex(index);

  const handleLinkClick = (path) => {
    navigate(path);
  };

  return (
    <div className="swipeable-cards">
      <Carousel
        selectedItem={currentIndex}
        onChange={handleChange}
        showArrows={false}
        showStatus={false}
        autoPlay
        interval={7000}
        showThumbs={false}
        transitionTime={800}
        swipeable
        emulateTouch
        infiniteLoop // Enables looping
      >
        {cards.map((card, index) => (
          <div key={index} className="carousel-slide">
            <div className="step-content">
              <h2>{card.title}</h2>
              <div className="card-image">
                <img src={card.image} alt={card.heading} />
              </div>
              <h1>{card.heading}</h1>
              <p>{card.description}</p>
              <button onClick={() => handleLinkClick('/project-optimization')}>{card.buttonText}</button>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default SwipeableCards;
