// src/components/ConfirmOptimization.js

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth } from '../../firebaseConfig';
import './ConfirmOptimization.css';

const API_BASE_URL = process.env.NODE_ENV === 'production' ? '' : 'http://localhost:5001';

const ConfirmOptimization = () => {
  const location = useLocation();
  const { assistantResponse: initialAssistantResponse } = location.state || {};
  const assistantResponse = initialAssistantResponse || '';

  const [parsedData, setParsedData] = useState({
    title: '',
    projectObjective: '',
    projectSummary: '',
    deliverables: '',
    skills: '',
    dataInsights: '',
    timeline: '',
  });

  const navigate = useNavigate();

  const parseAssistantResponse = (responseText) => {
    const result = {};
    const headings = [
      { key: 'title', headings: ['Project Title:'] },
      { key: 'projectObjective', headings: ['Project Objective:'] },
      { key: 'projectSummary', headings: ['Project Scope:'] },
      { key: 'deliverables', headings: ['Deliverables:', 'Deliverable:'] },
      { key: 'skills', headings: ['Skills Required:'] },
      { key: 'dataInsights', headings: ['Data-Backed Insights:'] },
      { key: 'timeline', headings: ['Timeline:'] },
    ];

    headings.forEach((section, index) => {
      const startHeadingsPattern = section.headings
        .map((h) => h.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&'))
        .join('|');

      const endHeadings = headings
        .slice(index + 1)
        .flatMap((s) => s.headings)
        .map((h) => h.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&'))
        .join('|');

      const pattern = new RegExp(
        '(?:' + startHeadingsPattern + ')\\s*([\\s\\S]*?)\\s*(?=' + (endHeadings || '$') + ')',
        'i'
      );
      const match = responseText.match(pattern);
      if (match) {
        let content = match[1].trim();
        content = content.replace(/\*/g, ''); // Remove all asterisks
        result[section.key] = content;
      }
    });

    return result;
  };

  useEffect(() => {
    if (assistantResponse) {
      const parsed = parseAssistantResponse(assistantResponse);
      setParsedData(parsed);
    }
  }, [assistantResponse]);

  const handleNext = () => {
    // Store project data temporarily
    localStorage.setItem('projectData', JSON.stringify({ ...parsedData, ...location.state }));
    navigate('/set-budget', {
      state: {
        ...location.state,
        ...parsedData,
      },
    });
  };

  const [showEmailPopup, setShowEmailPopup] = useState(false);
  const [email, setEmail] = useState('');
  const [emailSentMessage, setEmailSentMessage] = useState('');

  const handleOpenEmailPopup = () => {
    setShowEmailPopup(true);
    setEmailSentMessage('');
  };

  const handleCloseEmailPopup = () => {
    setShowEmailPopup(false);
  };

  const handleSendEmail = async () => {
    // Ensure no undefined fields before sending
    const safeParsedData = {
      title: parsedData.title || '',
      projectObjective: parsedData.projectObjective || '',
      projectSummary: parsedData.projectSummary || '',
      deliverables: parsedData.deliverables || '',
      skills: parsedData.skills || '',
      dataInsights: parsedData.dataInsights || '',
      timeline: parsedData.timeline || '',
    };

    try {
      const response = await fetch(`${API_BASE_URL}/api/send-email`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email,
          parsedData: safeParsedData,
          assistantResponse: assistantResponse || '',
          ownerId: auth.currentUser?.uid || '',
        }),
      });

      if (response.ok) {
        setEmailSentMessage("Congratulations, your project brief has been saved and emailed to you. Be sure to check your junk mail, so your dreams don't die!");
        // Close the popup after sending
        setShowEmailPopup(false);
      } else {
        setEmailSentMessage("There was an issue sending your email. Please try again.");
      }
    } catch (error) {
      console.error('Error sending email:', error);
      setEmailSentMessage("There was an error. Please try again.");
    }
  };

  // Resizable Textarea Component with Minimum Height
  const ResizableTextarea = ({ value, onChange, readOnly, placeholder, className }) => {
    const textareaRef = useRef(null);

    useEffect(() => {
      const element = textareaRef.current;
      if (element) {
        element.style.height = 'auto'; // Reset the height
        const minHeight = 300; // Minimum height in pixels
        const newHeight = Math.max(element.scrollHeight, minHeight);
        element.style.height = newHeight + 'px'; // Set height to max of scrollHeight and minHeight
      }
    }, [value]);

    return (
      <textarea
        ref={textareaRef}
        value={value}
        onChange={onChange}
        readOnly={readOnly}
        placeholder={placeholder}
        className={className}
      />
    );
  };

  return (
    <div className="page-container fade-in">
      <div className="confirm-optimization-container">
        <h1>{parsedData.title}</h1>
        <h2>
          Your brief, powered by <span> Moccet</span>
        </h2>
        <div className="optimization-content">
          {/* Data Insights */}
          <div className="input-group123123">
            <strong className="input-title">Data-Backed Insights</strong>
            <ResizableTextarea
              value={parsedData.dataInsights}
              readOnly
              placeholder="Data Insights"
              className="input-box123123"
            />
          </div>
          {/* Project Summary */}
          <div className="input-group123123">
            <strong className="input-title">Project Summary</strong>
            <ResizableTextarea
              value={parsedData.projectSummary}
              onChange={(e) => {
                setParsedData((prev) => ({
                  ...prev,
                  projectSummary: e.target.value,
                }));
              }}
              placeholder="Project Summary"
              className="input-box123123"
            />
          </div>
          {/* Deliverables */}
          <div className="input-group123123">
            <strong className="input-title">Deliverables</strong>
            <ResizableTextarea
              value={parsedData.deliverables}
              onChange={(e) => {
                setParsedData((prev) => ({
                  ...prev,
                  deliverables: e.target.value,
                }));
              }}
              placeholder="Deliverables"
              className="input-box123123"
            />
          </div>
          {/* Timeline (optional if needed, remains readOnly) */}
          <div className="input-group123123">
            <strong className="input-title">Timeline</strong>
            <ResizableTextarea
              value={
                parsedData.timeline ||
                'Based on projects of similar nature, the predicted timeline for your project is 1-3 weeks.'
              }
              onChange={(e) => {
                setParsedData((prev) => ({
                  ...prev,
                  timeline: e.target.value,
                }));
              }}
              placeholder="Timeline"
              className="input-box123123"
            />
          </div>
        </div>
        
        {/* Buttons Container */}
        <div className="buttons-container">
          <div className="fund-button" onClick={handleNext}>
            Upload
          </div>
          <div className="fund-button2" onClick={handleOpenEmailPopup}>
            Send to Email
          </div>
        </div>
        
        {emailSentMessage && <p className="email-sent-message">{emailSentMessage}</p>}
      </div>

      {showEmailPopup && (
        <div className="popup-overlay">
          <div className="popup-content">
            <h2>Enter Your Email</h2>
            <input
              type="email"
              placeholder="you@example.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="email-input"
            />
            <div className="popup-buttons">
              <button className="popup-button" onClick={handleSendEmail}>
                Send to Email
              </button>
              <button className="popup-button cancel" onClick={handleCloseEmailPopup}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ConfirmOptimization;
