import React, { useState } from 'react';
import { auth, db } from '../../firebaseConfig';
import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
  signInWithEmailAndPassword
} from 'firebase/auth';
import { getDoc, setDoc, doc } from 'firebase/firestore';
import { useNavigate, useLocation } from 'react-router-dom';
import './BusinessSignUp.css';
import appleLogo from '../../images/apple.png';
import googleLogo from '../../images/google.png';

function BusinessSignUp() {
  const location = useLocation();
  const navigate = useNavigate();
  const projectData = location.state || {};

  const [step, setStep] = useState(1); // Step 1: email, Step 2: password, Step 3: finalize sign up

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showTermsPopup, setShowTermsPopup] = useState(false);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  console.log('State received in BusinessSignUp:', location.state);

  const handleShowTerms = () => {
    setShowTermsPopup(true);
  };

  const handleCloseTerms = () => {
    setShowTermsPopup(false);
  };

  const handleNext = async () => {
    setError('');

    if (step === 1) {
      // Validate email
      if (email.trim().length === 0) {
        setError('Please enter your email.');
        return;
      }
      // Move to step 2 to show password
      setStep(2);
    } else if (step === 2) {
      // Validate password
      if (password.trim().length === 0) {
        setError('Please enter your password.');
        return;
      }
      // Move to step 3 for final sign up
      // Final sign up attempt happens here
      await handleSignUp();
    }
  };

  const handleSignUp = async () => {
    setError('');
    setMessage('');
    if (!termsAccepted) {
      setError('You must agree to the Terms & Conditions prior to sign up.');
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      const userInfo = {
        email,
        role: 'business',
        roleAssigned: false,
      };

      await setDoc(doc(db, 'users', user.uid), userInfo);

      // Immediately log in the user
      await signInWithEmailAndPassword(auth, email, password);

      // Navigate to the Stripe payment page
      navigate('/payment', {
        state: {
          ...projectData,
        },
      });
    } catch (error) {
      console.error('Error signing up:', error);
      setError('Error signing up. Please try again.');
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      const userDocRef = doc(db, 'users', user.uid);
      const userDocSnap = await getDoc(userDocRef);
      if (!userDocSnap.exists()) {
        const userInfo = {
          email: user.email,
          role: 'business',
          roleAssigned: false,
        };
        await setDoc(userDocRef, userInfo);
      }

      navigate('/payment', {
        state: {
          ...projectData,
        },
      });
    } catch (error) {
      console.error('Error signing in with Google:', error);
      setError('Error signing in with Google. Please try again.');
    }
  };

  const handleAppleSignIn = async () => {
    try {
      const provider = new OAuthProvider('apple.com');
      await signInWithPopup(auth, provider);
      navigate('/payment');
    } catch (error) {
      console.error('Error signing in with Apple:', error);
      setError('Error signing in with Apple. Please try again.');
    }
  };

  return (
    <div className="signup-container">
      <h2>Sign Up</h2>

      {/* Step 1: Email Field */}
      {step >= 1 && (
        <input
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      )}

      {/* Step 2: Password Field (only visible after email entered and continue clicked once) */}
      {step >= 2 && (
        <input
          type="password"
          placeholder="Enter your password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      )}

      {/* Terms & Conditions always visible */}
      <div className="terms-checkbox" style={{ marginTop: '20px' }}>
        <label>
          <input
            type="checkbox"
            checked={termsAccepted}
            onChange={(e) => setTermsAccepted(e.target.checked)}
          />
          I agree to Moccet’s&nbsp;
          <span className="terms-link" onClick={handleShowTerms}>
            Terms & Conditions
          </span>.
        </label>
      </div>

      {/* Continue button always visible - controls step logic or final sign up */}
      <button className="continue-button" onClick={handleNext}>
        Continue
      </button>

      {error && <p className="error-text">{error}</p>}
      {message && <p className="success-text">{message}</p>}

      <hr className="divider" />

      {/* Apple and Google sign-in always visible */}
      <button className="social-button apple-button" onClick={handleAppleSignIn}>
        <img src={appleLogo} alt="Apple Logo" className="social-logo" />
        Continue with Apple
      </button>
      <button className="social-button google-button" onClick={handleGoogleSignIn}>
        <img src={googleLogo} alt="Google Logo" className="social-logo" />
        Continue with Google
      </button>

      {showTermsPopup && (
        <div className="terms-popup">
          <div className="terms-popup-content">
            <div className="terms-header">
              <h2>Terms and Conditions</h2>
              <span className="close-popup" onClick={handleCloseTerms}>
                &times;
              </span>
            </div>
            <div className="terms-content">
              {/* Your Business Terms and Conditions remain unchanged */}
              <h2>Business Terms and Conditions</h2>
              <p>
                (Place your terms and conditions here)
              </p>
              {/* ... rest of the terms ... */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default BusinessSignUp;
