import React, { useEffect, useState } from 'react';
import { auth, db } from '../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import './BusinessDash.css';
import Footer from '../components/Footer.js';

const BusinessDash = () => {
  const [loaded, setLoaded] = useState(false);
  const [displayName, setDisplayName] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoaded(true);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const fetchUserName = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          if (userData.role === 'business') {
            setDisplayName(userData.businessName || '');
          } else {
            setDisplayName(userData.firstName || '');
          }
        }
      }
    };

    fetchUserName();
  }, []);


  return (
    <div className={`dashboard-container1 ${loaded ? 'loaded' : ''}`}>
      <div className='d124'>
      <div className="upper-section1">
        <div className="welcome-message1">
          <h2>
            Welcome <span>{displayName}</span>
          </h2>
        </div>
      </div>
      <div className="dashboard-links-container1">
        <div className="dashboard-links1">
          <span className="link-item1" onClick={() => navigate('/business-projects')}>
            current projects
          </span>
          <span className="link-item1" onClick={() => navigate('/applications')}>
            applications
          </span>
          <span className="link-item1" onClick={() => navigate('/project-optimization')}>
            upload a project
          </span>
          {/* Additional Links for Mobile View */}
          <span className="link-item1 mobile-only" onClick={() => navigate('/business-profile')}>
            account
          </span>
          <span className="link-item1 mobile-only" onClick={() => navigate('/business-chats')}>
            collaborate
          </span>
        </div>
      </div>
      </div>
      <Footer />
    </div>
  );
};

export default BusinessDash;
