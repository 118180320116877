// src/components/NavBar.js

import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth, db } from '../firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';
import {
  collection,
  query,
  where,
  onSnapshot,
  doc,
} from 'firebase/firestore';
import './NavBar.css';
import logo from '../images/logo5.png';

function NavBar() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [unreadChats, setUnreadChats] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    let unsubscribeFromAuth = null;
    let unsubscribeFromChats = null;
    let unsubscribeFromUserDoc = null;

    unsubscribeFromAuth = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setIsLoggedIn(true);

        try {
          const userDocRef = doc(db, 'users', user.uid);

          // Set up a real-time listener for the user document
          unsubscribeFromUserDoc = onSnapshot(
            userDocRef,
            (docSnapshot) => {
              if (docSnapshot.exists()) {
                const userData = docSnapshot.data();
                setUserRole(userData.role);

                if (
                  userData.role === 'student' ||
                  userData.role === 'business'
                ) {
                  if (unsubscribeFromChats) {
                    unsubscribeFromChats();
                  }
                  unsubscribeFromChats = fetchUnreadChatsCount();
                }
              } else {
                console.log('Waiting for user document to be created...');
              }
            },
            (error) => {
              console.error('Error listening to user document:', error);
            }
          );
        } catch (error) {
          console.error('Error fetching user data from Firestore:', error);
        }
      } else {
        setIsLoggedIn(false);
        setUserRole(null);
        setUnreadChats(0);
        if (unsubscribeFromChats) {
          unsubscribeFromChats();
        }
        if (unsubscribeFromUserDoc) {
          unsubscribeFromUserDoc();
        }
      }
    });

    return () => {
      if (unsubscribeFromAuth) unsubscribeFromAuth();
      if (unsubscribeFromChats) unsubscribeFromChats();
      if (unsubscribeFromUserDoc) unsubscribeFromUserDoc();
    };
  }, []);

  const fetchUnreadChatsCount = () => {
    const user = auth.currentUser;
    if (!user) {
      console.error('No authenticated user found.');
      return;
    }

    const chatRoomsRef = collection(db, 'chatRooms');
    const q = query(chatRoomsRef, where('participants', 'array-contains', user.uid));

    const unsubscribe = onSnapshot(
      q,
      (querySnapshot) => {
        let totalUnread = 0;

        querySnapshot.forEach((chatDoc) => {
          const chatData = chatDoc.data();
          const unreadCounts = chatData.unreadCounts || {};
          const unreadCount = unreadCounts[user.uid] || 0;
          totalUnread += unreadCount;
        });

        setUnreadChats(totalUnread);
      },
      (error) => {
        console.error('Error fetching unread chats:', error);
      }
    );

    return unsubscribe;
  };

  const handleLinkClick = (path) => {
    navigate(path);
    setIsMobileMenuOpen(false);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  // Function to scroll to a section on the homepage
  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      const offset = -200; // Adjust this value to control how much higher to scroll
      const elementPosition = section.getBoundingClientRect().top + window.pageYOffset + offset;
      window.scrollTo({ top: elementPosition, behavior: 'smooth' });
    }
  };
  

  const handleScrollToSection = (id) => {
    if (location.pathname === '/') {
      scrollToSection(id);
    } else {
      navigate('/');
      // Wait for the homepage to load before scrolling
      setTimeout(() => scrollToSection(id), 100);
    }
    setIsMobileMenuOpen(false);
  };

  let leftLinks = [];
  let rightLinks = [];

  if (isLoggedIn && userRole === 'student') {
    leftLinks = [
      { name: 'account', path: '/student-profile' },
      { name: 'project board', path: '/project-board' },
      { name: 'dashboard', path: '/student-dashboard' },
    ];
    rightLinks = [
      { name: 'support', path: '/support' },
      {
        name: 'collaborate',
        path: '/student-chats',
        showBadge: true,
      },
      {
        name: 'log out',
        action: () => {
          auth.signOut();
          navigate('/');
        },
      },
    ];
  } else if (isLoggedIn && userRole === 'business') {
    leftLinks = [
      { name: 'account', path: '/business-profile' },
      { name: 'upload project', path: '/project-optimization' },
      { name: 'dashboard', path: '/business-dashboard' },
    ];
    rightLinks = [
      { name: 'applications', path: '/applications' },
      {
        name: 'collaborate',
        path: '/business-chats',
        showBadge: true,
      },
      {
        name: 'log out',
        action: () => {
          auth.signOut();
          navigate('/');
        },
      },
    ];
  } else {
    leftLinks = [];
    rightLinks = [
      {
        name: 'Create',
        action: () => handleScrollToSection('create'),
      },
      {
        name: 'Collaborate',
        action: () => handleScrollToSection('work'),
      },
      { name: 'Login', path: '/signin' },
      { name: 'Sign Up', path: '/signup' },
    ];
  }

  const paymentPages = [
    '/payment',
    '/project-optimization',
    '/business-signup',
    '/confirm-optimization',
    '/set-budget',
  ];

  const currentPath = location.pathname.toLowerCase().replace(/\/+$/, '');
  const isPaymentPage = paymentPages.includes(currentPath);
  const navbarBackgroundColor = isPaymentPage ? 'white' : '#dde1ff';

  return (
    <div className="bar" style={{ backgroundColor: navbarBackgroundColor }}>
      <nav className="navbar" style={{ backgroundColor: navbarBackgroundColor }}>
        <div className="container" style={{ backgroundColor: navbarBackgroundColor }}>
          {/* Mobile Logo */}
          <div className="mobile-logo" onClick={() => handleLinkClick('/')}>
            <img src={logo} alt="Moccet Logo" className="mobile-logo-image" />
          </div>

          <div className={`nav-content ${isPaymentPage ? 'center-logo' : ''}`}>
            {/* Left Links */}
            {!isPaymentPage && isLoggedIn && leftLinks.length > 0 && (
              <div className="nav-links left-links">
                {leftLinks.map((link, index) => (
                  <span
                    key={index}
                    onClick={() =>
                      link.action ? link.action() : handleLinkClick(link.path)
                    }
                  >
                    {link.name}
                  </span>
                ))}
              </div>
            )}

            {/* Centered Logo */}
            <div className="logo" onClick={() => handleLinkClick('/')}>
              <img src={logo} alt="Moccet Logo" className="logo-image" />
            </div>

            {/* Right Links */}
            {!isPaymentPage && rightLinks.length > 0 && (
              <div
                className={`nav-links right-links ${
                  !isLoggedIn ? 'not-logged-in' : ''
                }`}
              >
                {rightLinks.map((link, index) =>
                  link.name === 'Sign Up' ? (
                    <button
                      key={index}
                      onClick={() =>
                        link.action ? link.action() : handleLinkClick(link.path)
                      }
                      className="sign-up-button"
                    >
                      {link.name}
                    </button>
                  ) : (
                    <span
                      key={index}
                      onClick={() =>
                        link.action ? link.action() : handleLinkClick(link.path)
                      }
                    >
                      {link.name}
                      {link.showBadge && unreadChats > 0 && (
                        <sup className="unread-count">{unreadChats}</sup>
                      )}
                    </span>
                  )
                )}
              </div>
            )}
          </div>

          {/* Hamburger Icon for Mobile */}
          {!isPaymentPage && (
            <div className="mobile-menu-icon" onClick={toggleMobileMenu}>
              {isMobileMenuOpen ? '✖' : '☰'}
            </div>
          )}
        </div>
      </nav>

      {/* Mobile Menu */}
      {!isPaymentPage && isMobileMenuOpen && (
        <div className="mobile-menu">
          {leftLinks.concat(rightLinks).map((link, index) =>
            link.name === 'Sign Up' ? (
              <button
                key={index}
                onClick={() =>
                  link.action ? link.action() : handleLinkClick(link.path)
                }
                className="sign-up-button"
              >
                {link.name}
              </button>
            ) : (
              <span
                key={index}
                onClick={() =>
                  link.action ? link.action() : handleLinkClick(link.path)
                }
                className={
                  link.showBadge
                    ? `collaborate-link ${unreadChats > 0 ? 'unread' : ''}`
                    : ''
                }
              >
                {link.name}
                {link.showBadge && unreadChats > 0 && (
                  <sup className="unread-count">{unreadChats}</sup>
                )}
              </span>
            )
          )}
        </div>
      )}
    </div>
  );
}

export default NavBar;
