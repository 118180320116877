import React, { useEffect, useRef } from 'react';
import './HowItWorks.css';
import image1 from '../../images/home1.png';
import image2 from '../../images/home2.png';
import image3 from '../../images/home3.png';
import image4 from '../../images/home4.png';
import image5 from '../../images/home5.png';
import image6 from '../../images/home6.png';


const HowItWorks = () => {
  const containerRef = useRef(null);

  const features = [
    {
      title: 'Business Strategy',
      description:
        'Get innovative, data-backed insights to shape your next big move with strategy support.',
      image: image1, // Add the path to your image
    },
    {
      title: 'Marketing Plan',
      description:
        'Discover creative marketing solutions tailored to your audience by some of the brightest minds in the field.',
      image: image2,
    },
    {
      title: 'Website/ UI Design',
      description:
        'Elevate your digital presence with innovative, user-friendly designs crafted by skilled designers',
      image: image3,
    },
    {
      title: 'Public Health Research',
      description:
        'Tackle complex health challenges with rigorous, research-driven insights from public health experts',
      image: image4,
    },
    {
      title: 'Educational Content',
      description:
        'Engage and educate your audience with expertly developed content.',
      image: image5,
    },
    {
      title: 'Coding',
      description:
        'Bring your ideas to life with cutting-edge code and development skills from tech leaders.',
      image: image6,
    },
  ];

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) {
          entries[0].target.classList.add('in-view');
          observer.unobserve(entries[0].target);
        }
      },
      {
        threshold: 0.1,
      }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  return (
    <div className="how-it-works-container" ref={containerRef}>
      <div className="how-it-works-container1">
        <h1>Project Suggestions</h1>
      </div>

      <div className="features-grid">
        {features.map((feature, index) => (
          <div className="feature-card" key={index}>
            <div className="feature-content">
              <h3>{feature.title}</h3>
              <p>{feature.description}</p>
            </div>
            <img
              src={feature.image}
              alt={`${feature.title} illustration`}
              className="feature-image"
            />
          </div>
        ))}
      </div>
    </div>
  );
};


export default HowItWorks;
