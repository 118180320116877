import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth } from '../../firebaseConfig';
import './ProjectOptimization.css';

const API_BASE_URL = process.env.NODE_ENV === 'production' ? '' : 'http://localhost:5001';

const placeholders = [
  'Describe your project - it all starts here.',
  'Whats your next big idea? It starts here.',
  'Need an MVP for your startup? Get started.',
  'New marketing campaign? It starts here.',
  'Looking for a web developer? Start now.',
  'Bold ideas to small details - it starts here.',
  'Type your vision. It starts here.',
  'What will you create? It starts here.'
];

const ProjectOptimization = () => {
  const location = useLocation();
  const { businessProblem = '', description = '' } = location.state || {};

  const initialProjectDescription = [businessProblem, description].filter(Boolean).join('\n\n');
  const [projectDescription, setProjectDescription] = useState(initialProjectDescription);
  const [loading, setLoading] = useState(false);

  // Placeholder animation states
  const [currentPlaceholderIndex, setCurrentPlaceholderIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  const navigate = useNavigate();
  const textareaRef = useRef(null);

  const minHeight = 50;   // Minimum height in pixels
  const maxHeight = 400;  // Maximum height in pixels

  const autoResize = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      // Reset height to auto to shrink if needed
      textarea.style.height = 'auto';
      let newHeight = textarea.scrollHeight;

      // Enforce min and max heights
      if (newHeight < minHeight) {
        newHeight = minHeight;
      } else if (newHeight > maxHeight) {
        newHeight = maxHeight;
      }

      textarea.style.height = newHeight + 'px';

      // If content exceeds maxHeight, allow scrolling
      if (textarea.scrollHeight > maxHeight) {
        textarea.style.overflowY = 'auto';
      } else {
        textarea.style.overflowY = 'hidden';
      }
    }
  };

  const handleOptimize = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}/api/upload`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ projectDescription }),
      });
      const data = await response.json();
      const assistantResponse = data.assistantResponse || 'No response received.';
      const summary = assistantResponse.substring(0, 100);

      const ownerId = auth.currentUser?.uid || '';
      const isCollaborating = false;
      const collaboratingBusinessId = null;
      const studentId = ownerId;
      const createdAt = new Date();

      navigate('/confirm-optimization', {
        state: {
          assistantResponse,
          projectDescription,
          summary,
          ownerId,
          isCollaborating,
          collaboratingBusinessId,
          studentId,
          createdAt,
        },
      });
    } catch (error) {
      console.error('Error processing optimization:', error);
    } finally {
      setLoading(false);
    }
  };

  // Cycle through placeholders
  useEffect(() => {
    const interval = setInterval(() => {
      setIsAnimating(true);
      setTimeout(() => {
        setCurrentPlaceholderIndex((prevIndex) => (prevIndex + 1) % placeholders.length);
      }, 400); // halfway point for animation
      setTimeout(() => {
        setIsAnimating(false);
      }, 800); // end of animation
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const handleFocus = () => {
    document.body.classList.add('input-focused');
  };

  const handleBlur = () => {
    document.body.classList.remove('input-focused');
  };

  const handleChange = (e) => {
    setProjectDescription(e.target.value);
    // Adjust height on every change
    autoResize();
  };

  // Initialize the textarea height on mount
  useEffect(() => {
    autoResize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="project-optimization-container">
      {loading ? (
        <div className="loading-screen">
          <h2 className="loading-title">Please hold while Moccet’s AI gathers insights and refines <span>your</span> project</h2>
          <div className="dots-container">
            <div className="dot dot1"></div>
            <div className="dot dot2"></div>
            <div className="dot dot3"></div>
            <div className="dot dot4"></div>
            <div className="dot dot5"></div>
          </div>
          <p className="loading-text">
            Did you know, Moccet’s AI leverages hundreds of data points and in-depth market trend analyses to optimize your project brief, delivering unparalleled value for you and maximizing returns.
          </p>
        </div>
      ) : (
        <>
          <h1 className="main-title">Complete any project with Moccet</h1>
          <p className="subtitle">
            Connect with Harvard and MIT talent to achieve the best results for your projects,
            powered by <span className="moccet-highlight">Moccet AI</span>.
          </p>

          <div className="input-container12">
            <textarea
              ref={textareaRef}
              className="input-box12"
              value={projectDescription}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              style={{
                height: minHeight + 'px',
                overflowY: 'hidden',
              }}
              placeholder=""
            ></textarea>

            {projectDescription.trim().length === 0 && (
              <div className="placeholder-wrapper">
                <div className={`placeholder-text ${isAnimating ? 'animate' : ''}`}>
                  {placeholders[currentPlaceholderIndex]}
                </div>
              </div>
            )}

            <button className={`arrow-button ${loading ? 'loading' : ''}`} onClick={handleOptimize}>
              <span className="arrow-icon">↑</span>
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default ProjectOptimization;
